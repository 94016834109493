@media (min-width: 642px) {
  .masonry {
    column-count: 2;
  }
}

@media (min-width: 1000px) {
    .masonry {
      column-count: 2;
      margin-left: 240px
    }
  }
  
@media (min-width: 1225px) {
    .masonry {
      column-count: 3;
      margin-left: 240px;
    }
  }
  
@media (min-width: 1565px) {
    .masonry {
      column-count: 4;
      margin-left: 240px;
    }
  }
