html,
body {
  height: 100vh;
  background-color: #FAF8F5;
}

.bg {
  background-image: linear-gradient(to right top, #FAF8F5, #FAF8F5);
  height: 100%;
  width: 100%;
  position: absolute;
  overflow-x: auto;
  overflow-y: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #FFFFFF inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill{
  -webkit-text-fill-color: #1D1A05 !important;
}
.active.picture {
  opacity: 1;
  transition-duration: 0.6s;
  transform: scale(1.08)
};

.picture {
  opacity: 0;
  transition-duration: 1s ease

};

@import url('https://fonts.googleapis.com/css?family=Poppins');